import AWS from 'aws-sdk';
import { uniqueId } from "@/utils/helpers.js";
import { v4 as uuid } from 'uuid';

export default class Uploader {
  constructor() {
    AWS.config.region = process.env.VUE_APP_AWS_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.VUE_APP_AWS_POOL_ID
    });

    AWS.config.credentials.get(function(error) {
      if(error) {
        throw new Error(error);
      }
    });

    this.s3 = new AWS.S3({
      params: {
        Bucket: process.env.VUE_APP_AWS_IMAGE_BUCKET
      }
    });
  }

  upload(file) {
    return new Promise((resolve, reject) => {
      const extension = file.name.split('.').pop();
      const key = `virtual-conferences/${uniqueId(4)}/${uuid()}.${extension}`;
      let params = {
        Key: key,
        Bucket: process.env.VUE_APP_AWS_IMAGE_BUCKET,
        ContentType: file.type,
        Body: file,
        ACL: 'public-read'
      };

      const promise = this.s3.putObject(params).promise();

      promise.then(function() {
        return resolve('/' + key);
      }).catch(function(e) {
        return reject(e)
      });
    });  
  }
}